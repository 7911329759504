import React, { useMemo } from "react"
import { EngineeringLayout } from "../../../layouts"
import WeUse from "../../../components/WeUse/WeUse"
import TopComponent from "../../../components/TopComponent/TopComponent"
import ZigZag from "../../../components/ZigZag/ZigZag"
import Hero from "../../../components/Hero/Hero"
import Contact from "../../../components/Contact/Contact"
import gifImage1 from "../../../assets/images/gif-images/gif-engineering5-1.svg"
import "./Engineering5.scss"
import Testimonial from "../../../components/Testimonial/Testimonial"
import TestimonialSlider from "../../../components/TestimonialSlider/TestimonialSlider"
import Wave from "../../../components/Wave/Wave"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../../constants"
import { useZigzagImageSides } from "../../../hooks/useZigzagImageSides"
import SEO from "../../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"

const ZIGZAG_IMAGE_SIDE_START = "right"

const Engineering5 = ({ pageContext: { pageData, lang, testimonials, companies }, data, location }) => {
  const {
    allDzemoApiZigzags,
    allDzemoApiArticles,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])
  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  return (
    <EngineeringLayout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="engineering-page-5">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        {pageData[lang].videos.length > 0 && (
          <section>
            <Hero data={pageData[lang].videos[0]} />
          </section>
        )}

        {allDzemoApiArticles.length > 0 && (
          <WeUse data={allDzemoApiArticles[0]} />
        )}

        <div className="gif-content-top">
          <img src={gifImage1} alt="gif" width="180" height="72" />
          <img src={gifImage1} alt="gif" width="180" height="72" />
          <img src={gifImage1} alt="gif" width="180" height="72" />
        </div>

        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                  }}
                />
              )
            })}
        </section>

        <div className="wave-wrapper">
          <Wave />
        </div>

        <section
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return <Testimonial key={id} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </EngineeringLayout>
  )
}

export default Engineering5
export const query = graphql`
  query engineering5PageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiVideos(
      filter: { en: { page: { id: { eq: $pageId } } } }
      limit: 1
    ) {
      nodes {
        en {
          id
          title
          video {
            alternativeText
            ext
            hash
            id
            name
            size
            url
          }
        }
        de {
          id
          title
          video {
            alternativeText
            ext
            hash
            id
            name
            size
            url
          }
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiArticles(
      filter: { en: { page: { id: { eq: $pageId } } } }
      limit: 1
    ) {
      nodes {
        en {
          id
          body
          title
        }
        de {
          id
          body
          title
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }
  }
`
